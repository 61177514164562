// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-ca-404-jsx": () => import("./../../../src/pages/ca/404.jsx" /* webpackChunkName: "component---src-pages-ca-404-jsx" */),
  "component---src-pages-ca-avis-legal-jsx": () => import("./../../../src/pages/ca/avis-legal.jsx" /* webpackChunkName: "component---src-pages-ca-avis-legal-jsx" */),
  "component---src-pages-ca-contacte-jsx": () => import("./../../../src/pages/ca/contacte.jsx" /* webpackChunkName: "component---src-pages-ca-contacte-jsx" */),
  "component---src-pages-ca-empresa-jsx": () => import("./../../../src/pages/ca/empresa.jsx" /* webpackChunkName: "component---src-pages-ca-empresa-jsx" */),
  "component---src-pages-ca-index-jsx": () => import("./../../../src/pages/ca/index.jsx" /* webpackChunkName: "component---src-pages-ca-index-jsx" */),
  "component---src-pages-ca-kit-digital-jsx": () => import("./../../../src/pages/ca/kit-digital.jsx" /* webpackChunkName: "component---src-pages-ca-kit-digital-jsx" */),
  "component---src-pages-ca-ofertes-de-feina-jsx": () => import("./../../../src/pages/ca/ofertes-de-feina.jsx" /* webpackChunkName: "component---src-pages-ca-ofertes-de-feina-jsx" */),
  "component---src-pages-ca-politica-cookies-jsx": () => import("./../../../src/pages/ca/politica-cookies.jsx" /* webpackChunkName: "component---src-pages-ca-politica-cookies-jsx" */),
  "component---src-pages-ca-projectes-jsx": () => import("./../../../src/pages/ca/projectes.jsx" /* webpackChunkName: "component---src-pages-ca-projectes-jsx" */),
  "component---src-pages-ca-serveis-jsx": () => import("./../../../src/pages/ca/serveis.jsx" /* webpackChunkName: "component---src-pages-ca-serveis-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-cookies-policy-jsx": () => import("./../../../src/pages/cookies-policy.jsx" /* webpackChunkName: "component---src-pages-cookies-policy-jsx" */),
  "component---src-pages-es-404-jsx": () => import("./../../../src/pages/es/404.jsx" /* webpackChunkName: "component---src-pages-es-404-jsx" */),
  "component---src-pages-es-aviso-legal-jsx": () => import("./../../../src/pages/es/aviso-legal.jsx" /* webpackChunkName: "component---src-pages-es-aviso-legal-jsx" */),
  "component---src-pages-es-contacto-jsx": () => import("./../../../src/pages/es/contacto.jsx" /* webpackChunkName: "component---src-pages-es-contacto-jsx" */),
  "component---src-pages-es-empresa-jsx": () => import("./../../../src/pages/es/empresa.jsx" /* webpackChunkName: "component---src-pages-es-empresa-jsx" */),
  "component---src-pages-es-index-jsx": () => import("./../../../src/pages/es/index.jsx" /* webpackChunkName: "component---src-pages-es-index-jsx" */),
  "component---src-pages-es-kit-digital-jsx": () => import("./../../../src/pages/es/kit-digital.jsx" /* webpackChunkName: "component---src-pages-es-kit-digital-jsx" */),
  "component---src-pages-es-ofertas-de-empleo-jsx": () => import("./../../../src/pages/es/ofertas-de-empleo.jsx" /* webpackChunkName: "component---src-pages-es-ofertas-de-empleo-jsx" */),
  "component---src-pages-es-politica-cookies-jsx": () => import("./../../../src/pages/es/politica-cookies.jsx" /* webpackChunkName: "component---src-pages-es-politica-cookies-jsx" */),
  "component---src-pages-es-proyectos-jsx": () => import("./../../../src/pages/es/proyectos.jsx" /* webpackChunkName: "component---src-pages-es-proyectos-jsx" */),
  "component---src-pages-es-servicios-jsx": () => import("./../../../src/pages/es/servicios.jsx" /* webpackChunkName: "component---src-pages-es-servicios-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-notices-jsx": () => import("./../../../src/pages/legal-notices.jsx" /* webpackChunkName: "component---src-pages-legal-notices-jsx" */),
  "component---src-pages-our-work-jsx": () => import("./../../../src/pages/our-work.jsx" /* webpackChunkName: "component---src-pages-our-work-jsx" */),
  "component---src-pages-what-we-do-jsx": () => import("./../../../src/pages/what-we-do.jsx" /* webpackChunkName: "component---src-pages-what-we-do-jsx" */),
  "component---src-template-pages-ficha-careers-jsx": () => import("./../../../src/template_pages/ficha_careers.jsx" /* webpackChunkName: "component---src-template-pages-ficha-careers-jsx" */),
  "component---src-template-pages-ficha-digital-kit-jsx": () => import("./../../../src/template_pages/ficha_digital_kit.jsx" /* webpackChunkName: "component---src-template-pages-ficha-digital-kit-jsx" */),
  "component---src-template-pages-ficha-projects-jsx": () => import("./../../../src/template_pages/ficha_projects.jsx" /* webpackChunkName: "component---src-template-pages-ficha-projects-jsx" */)
}

